import React, { useState } from "react";
import "../Assets/Products.css";
import ProductsImage2 from "../Assets/Assets/product-image-2.png";
import ProductsImage5 from "../Assets/Assets/product-image-5.png";
import ProductsImage7 from "../Assets/Assets/product-image-7.png";
import ProductImage8 from "../Assets/Assets/product-image-8.png";
import ProductImage9 from "../Assets/Assets/product-image-9.png";
import WnPay from "../Assets/Assets/Wnpay.png";
import WnSoc from "../Assets/Assets/WnSoc.png";
import WnDigital from "../Assets/Assets/WnDigital.png";
import WnvoecProduct from "../Assets/Assets/WnHomelogo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Services from "./Services";

const Products = () => {

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const { t } = useTranslation();

  return (
    <div>
      {/* section 1 */}
      <div className=" container-fluid main-product">
        <div className="row">
          <div className="col-md-12">
            <div className="products-section-1">
              <div className="products-content-1">
                <h2 className="products-heading-1">
                  {t("productsPage.products")}
                </h2>
                <p className="products-para-1">
                  {t("productsPage.products-1")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 2 */}
      <div className="products-section-2">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 col-lg-6 col-12">
              <div className="products-content-2">
                <p className="products-heading-2">
                  {t("productsPage.products-2")}
                </p>
                <p className="products-para-2">
                  {t("productsPage.products-3")} {" "}
                  {showFullText ? (
                    <>
                      {t("productsPage.products-4")}
                    </>
                  ) : null}
                </p>
              </div>
              <div className="MoreBtn">
                <button className="products-para-btn" onClick={toggleText}>
                  {showFullText ?
                    t("productsPage.products-5")
                    :
                    t("productsPage.products-6")
                  }
                </button>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-md-12 col-lg-5 col-12">
              <div className="products-content-3">
                <img src={ProductsImage2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 3 */}
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mt-5">
            <p className="products-heading-3">
              {t("productsPage.products-7")}
            </p>
            <p className="products-heading-4">
              {t("productsPage.products-8")}
            </p>
            <p className="products-para-3">
              {t("productsPage.products-9")}
              <br />
              {t("productsPage.products-10")}
            </p>
          </div>
        </div>
      </div>

      {/* section 4 */}
      <div className="container mt-5 mb-4">
        <div className="row RowCard">
          <div className="">
            <div className="flip-card mx-auto">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img
                    src={ProductsImage7}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  className="flip-card-back py-auto"
                  style={{ backgroundColor: "#77ad18" }}
                >
                  <p>
                    {t("productsPage.products-11")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
             <Link to="/wnTracker">
            <div className="flip-card mx-auto">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img
                    src={ProductsImage5}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  className="flip-card-back"
                  style={{ backgroundColor: "#005B3A" }}
                >
                  <p>
                    {t("productsPage.products-12")}
                  </p>
                </div>
              </div>
            </div>
    </Link>
          </div>
          <div className="">
              <Link to="/wnPay">
            <div className="flip-card mx-auto">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img
                    src={WnPay}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  className="flip-card-back"
                  style={{ backgroundColor: "rgba(0, 94, 94, 1)" }}
                >
                  <p>
                    {t("productsPage.products-13")}
                  </p>
                </div>
              </div>
            </div>
              </Link>
          </div>
          <div className="">
            <div className="flip-card mx-auto">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img
                    src={ProductImage8}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  className="flip-card-back"
                  style={{ backgroundColor: "#27004C" }}
                >
                  <p>
                    {t("productsPage.products-14")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
          <Link to="/wndepot">
            <div class="flip-card mx-auto">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={ProductImage9}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  class="flip-card-back"
                  style={{ backgroundColor: "#560056" }}
                >
                  <p>
                    {t("productsPage.products-15")}
                  </p>
                </div>
              </div>
            </div>
 </Link>
          </div>
          <div className="">
            <div class="flip-card mx-auto">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={WnSoc}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  class="flip-card-back"
                  style={{ backgroundColor: "#1D1D59" }}
                >
                  <p>
                    {t("productsPage.products-16")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div class="flip-card mx-auto">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={WnDigital}
                    alt="Avatar"
                    style={{
                      marginTop: "55px"
                    }}
                  />
                </div>
                <div
                  class="flip-card-back"
                  style={{ backgroundColor: "#00387C" }}
                >
                  <p>
                    {t("productsPage.products-17")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="">
            <Link to="/wnhome">
              <div class="flip-card mx-auto">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img
                      src={WnvoecProduct}
                      alt="Avatar"
                      style={{
                        marginTop: "55px"
                      }}
                    />
                  </div>
                  <div
                    class="flip-card-back"
                    style={{ backgroundColor: "rgba(221,30,37,255)" }}
                  >
                    <p>
                      {t("productsPage.products-18")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
         

        </div>
      </div>

      {/* section 5 */}
      <div className="products-section-5">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-2"></div> */}
            <div className="col-md-12 col-sm-12">
              <div className="products-content-5">
                <div className="productConatctBtn">
                  <p className="products-heading-5">
                    {t("productsPage.products-19")}
                    {t("productsPage.products-20")}
                    {t("productsPage.products-21")}
                    {t("productsPage.products-22")}
                  </p>
                </div>
                <div>
                   <Link className="text-decoration-none" to="/joinus">
                    <button className="product-Contact-Btn">
                      {t("productsPage.products-23")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="col-md-2"></div> */}
          </div>
        </div>
      </div>

      {/* section 6 */}
      <Services />
    </div>
  );
};

export default Products;
